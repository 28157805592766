export const uniqArray = (arrArg: Array<any>): Array<any> =>
    arrArg.filter((elem, pos, arr) => arr.indexOf(elem) == pos);

import { createSelectorCreator, defaultMemoize } from "reselect";
import isEqual from "lodash/isEqual";
// create a "selector creator" that uses lodash.isEqual instead of ===
export const createDeepEqualSelector = createSelectorCreator(defaultMemoize, isEqual);

export const titleCase = (str: string): string =>
    str
        .toLowerCase()
        .split(" ")
        .map(v => v.charAt(0).toUpperCase() + v.slice(1))
        .join(" ");

export const addCommas = (x: number): string | number => {
    if (typeof x == "number" && typeof Intl == "object" && typeof Intl.NumberFormat == "function") {
        return x.toLocaleString();
    }
    return x;
};
export const addCommasN = (x?: number): string | number | null => (x == null ? null : addCommas(x));

// Flow Item Type:  Convert internal type to one suitable for showing to the user
export const renderFlowItemType = (itemType: string): string => {
    let audienceDiscoveryName = "Insights";

    if (itemType == "discovery") {
        audienceDiscoveryName = "Audience Insights";
    }

    const itemTypeL = itemType.toLowerCase();
    const fixes = {
        empty: "item",
        offermerge: "offer",
        houseexpand: "house expand",
        svdedupe: "dedupe",
        fromcloud: "from cloud",
        tocloud: "to cloud",
        singleview: "SingleView",
        flowcontrol: "flow control",
        flowexpression: "expression",
        cannedreport: "canned",
        flowdescription: "description",
        scriptdbui: "Script DB-UI",
        multiexport: "Multi-Export",
        discovery: audienceDiscoveryName,
        discoveryinsights: "Discovery Insights",
        audienceinsights: "Audience Insights",
        insights: "Basic Insights",
    };
    return fixes[itemTypeL] != null ? fixes[itemTypeL] : itemType;
};

export const toJson = (response: Response): any => response.json();
