import { request } from "../helpers/httpInterceptor";
import h from "../helpers";
import { notifyError, notifyGreen } from "./notifyActions";
import { requestSessionInfo, requestEnabledFeatures } from "./sessionActions";
import { Dispatch } from "../types/types";
import { IAppState } from "../types/stores/index";
import { ICompanyTapadDeliveryType } from "../types/stores/companyTable";
import { ICompanyDestinationDedupeSettings } from "./../types/stores/companyTable";
import { ISingleViewControl, ISVClientKeys } from "../types/stores/singleView";
import { ICompany, ICompanyView } from "../types/stores/companyTable";
import {
    ISetCompanyIsSaving,
    ISetCompanyIsLoading,
    ISetCompanyIsLoadingFolders,
    ISetCompanyIsLoadingParentFolders,
    ISetCompanyIsLoadingVerticals,
    ISetCompanyIsLoadingUsers,
    ISetCompanyByIdIsLoading,
    ISetCompanyIsLoadingDestinations,
    ISetCompanyIsLoadingFeatures,
    ISetCompanyIsLoadingSnowflakeSettings,
    IDeploySetting,
} from "../types/actions/companies";
import {
    SAVING_COMPANY_DETAILS,
    LOADING_COMPANY_DETAILS,
    LOADING_COMPANY_FOLDERS,
    LOADING_COMPANY_PARENT_FOLDERS,
    LOADING_COMPANY_VERTICALS,
    LOADING_COMPANY_DESTINATIONS,
    LOADING_COMPANY_USERS,
    LOADING_COMPANY_BY_ID,
    LOADING_COMPANY_FEATURES,
    LOADING_COMPANY_ACTIVATION_SCHEDULES,
    SET_COMPANY_DETAILS,
    SET_COMPANY_CREATED_DATE,
    SET_COMPANY_UPDATED_DATE,
    SET_COMPANY_SV_CLIENT_KEYS,
    SET_COMPANY_SV_TABLES,
    SET_COMPANY_SV_CONTROL,
    SET_COMPANY_FOLDER_EXIST,
    SET_COMPANY_TAPAD_DELIVERY_TYPES,
    SET_COMPANY_DESTINATION_DEDUPE_SETTINGS,
    SET_COMPANY_VERTICALS,
    SET_COMPANY_DESTINATIONS,
    SET_SELECTED_COMPANY,
    SET_COMPANY_PDF_CONTENT,
    SET_COMPANY_PDF_FILENAME,
    SET_COMPANY_USERS,
    SET_COMPANY_BY_ID,
    SET_COMPANY_CURRENT_VERTICAL,
    SET_COMPANY_RESTRICTED_USERNAME,
    SET_COMPANY_RESTRICTED_PASSWORD,
    SET_COMPANY_SNOWFLAKE_SETTINGS,
    SET_DEPLOY_SETTINGS,
    SET_FLOW_CONTROL_DEPLOY_SETTINGS,
    LOADING_COMPANY_SNOWFLAKE_SETTINGS,
    SET_NOT_ALLOWED_DEPLOY_SNOWFLAKE_TABLES,
} from "../reducers/companies";

import {
    SET_TAPAD_DELIVERY_TYPES,
    SET_DESTINATION_DEDUPE_SETTINGS,
    SET_ACTIVATION_DESTINATION_SCHEDULES,
} from "../reducers/vars";
import { ISnowflakeSettings } from "../types/stores/snowflakeSettings";
import { ISetCompanyIsLoadingActivationSchedules } from "../types/actions/companies";
import { ISchedule } from "../types/stores/schedules";
import { requestFieldVisibilityTypesDefault } from "./adminDesignerActions";
import { GetCompanyFeatures } from "./featuresActions";

const formatDate = (dateArg: string): string | undefined => {
    if (dateArg != null) {
        let MyDateStringValue = dateArg;
        let value = new Date(parseInt(MyDateStringValue.replace(/(^.*\()|([+-].*$)/g, "")));
        let year = value.getFullYear();
        let month = value.getMonth() + 1;
        let date = value.getDate();
        let minutes = value.getMinutes() == 0 ? "00" : value.getMinutes();
        let hours = value.getHours();
        let formattedDate = `${year}/${month}/${date} ${hours}:${minutes}`;
        return formattedDate;
    }
};

export const SetCompanyIsSaving = (newValue: boolean): ISetCompanyIsSaving => ({
    type: SAVING_COMPANY_DETAILS,
    isSaving: newValue,
});

export const SetCompanyIsLoading = (newValue: boolean): ISetCompanyIsLoading => ({
    type: LOADING_COMPANY_DETAILS,
    isLoading: newValue,
});

export const SetCompanyIsLoadingFolders = (newValue: boolean): ISetCompanyIsLoadingFolders => ({
    type: LOADING_COMPANY_FOLDERS,
    isLoadingFolders: newValue,
});

export const SetCompanyIsLoadingParentFolders = (newValue: boolean): ISetCompanyIsLoadingParentFolders => ({
    type: LOADING_COMPANY_PARENT_FOLDERS,
    isLoadingParentFolders: newValue,
});

export const SetCompanyIsLoadingVerticals = (newValue: boolean): ISetCompanyIsLoadingVerticals => ({
    type: LOADING_COMPANY_VERTICALS,
    isLoadingVerticals: newValue,
});

export const SetCompanyIsLoadingUsers = (newValue: boolean): ISetCompanyIsLoadingUsers => ({
    type: LOADING_COMPANY_USERS,
    isLoadingUsers: newValue,
});

export const SetCompanyByIdIsLoading = (newValue: boolean): ISetCompanyByIdIsLoading => ({
    type: LOADING_COMPANY_BY_ID,
    isLoadingCompanyById: newValue,
});

export const SetCompanyIsLoadingDestinations = (newValue: boolean): ISetCompanyIsLoadingDestinations => ({
    type: LOADING_COMPANY_DESTINATIONS,
    isLoadingDestinations: newValue,
});

export const SetCompanyIsLoadingFeatures = (newValue: boolean): ISetCompanyIsLoadingFeatures => ({
    type: LOADING_COMPANY_FEATURES,
    isLoadingFeatures: newValue,
});

export const SetCompanyIsLoadingSnowflakeSettings = (newValue: boolean): ISetCompanyIsLoadingSnowflakeSettings => ({
    type: LOADING_COMPANY_SNOWFLAKE_SETTINGS,
    isLoadingSnowflakeSettings: newValue,
});

export const SetCompanyIsLoadingActivationSchedules = (newValue: boolean): ISetCompanyIsLoadingActivationSchedules => ({
    type: LOADING_COMPANY_ACTIVATION_SCHEDULES,
    isLoadingActivationSchedules: newValue,
});

export const SetCompanyDetails =
    (newCompanyDetails: ICompanyView) =>
    (dispatch: Dispatch): void => {
        dispatch({
            type: SET_COMPANY_DETAILS,
            company: newCompanyDetails,
        });
    };

export const SetNewCompanyPdf =
    (newCompanyPdf: File) =>
    (dispatch: Dispatch): void => {
        dispatch({
            type: SET_COMPANY_PDF_CONTENT,
            pdfContent: newCompanyPdf,
        });
        dispatch({
            type: SET_COMPANY_PDF_FILENAME,
            pdfFileName: newCompanyPdf.name,
        });
    };

export const SetSelectedCompany =
    (newSelectedCompany: number) =>
    (dispatch: Dispatch, getState: () => IAppState): void => {
        const state = getState();
        const currentSelectedCompany = state.companyEdit.selectedCompany
            ? parseInt(state.companyEdit.selectedCompany)
            : null;

        if (currentSelectedCompany !== newSelectedCompany) {
            dispatch({
                type: SET_SELECTED_COMPANY,
                selectedCompany: newSelectedCompany,
            });
        }
    };

export const SetCompanySingleViewControlSettings =
    (svControl: ISingleViewControl) =>
    (dispatch: Dispatch): void => {
        dispatch({
            type: SET_COMPANY_SV_CONTROL,
            svControl,
        });
    };

export const SetCompanySingleViewClientKeys =
    (clientKeys: Array<ISVClientKeys>) =>
    (dispatch: Dispatch): void => {
        dispatch({
            type: SET_COMPANY_SV_CLIENT_KEYS,
            svClientKeys: clientKeys,
        });
    };

export const SetCompanyCurrentVertical =
    (newVertical: number) =>
    (dispatch: Dispatch): void => {
        dispatch({
            type: SET_COMPANY_CURRENT_VERTICAL,
            currentVertical: newVertical,
        });
    };

export const SetCompanyRestrictedUsername =
    (restrictedUsername: string) =>
    (dispatch: Dispatch): void => {
        dispatch({
            type: SET_COMPANY_RESTRICTED_USERNAME,
            restrictedUsername,
        });
    };

export const SetCompanyRestrictedPassword =
    (restrictedPassword: string) =>
    (dispatch: Dispatch): void => {
        dispatch({
            type: SET_COMPANY_RESTRICTED_PASSWORD,
            restrictedPassword,
        });
    };

export const SetCompanyTapadDeliveryTypes =
    (tapadDeliveryTypes: ICompanyTapadDeliveryType[]) =>
    (dispatch: Dispatch): void => {
        dispatch({
            type: SET_COMPANY_TAPAD_DELIVERY_TYPES,
            tapadDeliveryTypes,
        });
    };

export const SetActivationDestinationSchedules =
    (activationDestinationSchedules: ISchedule[]) =>
    (dispatch: Dispatch): void => {
        dispatch({
            type: SET_ACTIVATION_DESTINATION_SCHEDULES,
            activationDestinationSchedules,
        });
    };

export const SetCompanyDestinationDedupeSettings =
    (destinationDedupeSettings: ICompanyDestinationDedupeSettings[]) =>
    (dispatch: Dispatch): void => {
        dispatch({
            type: SET_COMPANY_DESTINATION_DEDUPE_SETTINGS,
            destinationDedupeSettings,
        });
    };

export const SetCompanySnowflakeSettings =
    (snowflakeSettings: Array<ISnowflakeSettings>) =>
    (dispatch: Dispatch): void => {
        dispatch({
            type: SET_COMPANY_SNOWFLAKE_SETTINGS,
            snowflakeSettings,
        });
    };

export const SetDeploySettings =
    (deploySettings: Array<IDeploySetting>) =>
    (dispatch: Dispatch): void => {
        dispatch({
            type: SET_DEPLOY_SETTINGS,
            deploySettings,
        });
    };

export const SetFlowControlDeploySettings =
    (flowControlDeploySettings: Array<IDeploySetting>) =>
    (dispatch: Dispatch): void => {
        dispatch({
            type: SET_FLOW_CONTROL_DEPLOY_SETTINGS,
            flowControlDeploySettings,
        });
    };

export const SaveCompanyDetails =
    (company: ICompany, currentVertical: number) =>
    (dispatch: Dispatch): void => {
        dispatch(SetCompanyIsSaving(true));
        //This should be improve later
        company.DataProviderPDF = null;
        request(
            "/Companies/EditCompanyDetailsSave",
            {
                method: "POST",
                credentials: "same-origin",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ company, CompanyVertical: currentVertical }),
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(requestFieldVisibilityTypesDefault());

                const response = data as unknown as { success: boolean; submittedHangfireJob: boolean };
                dispatch(SetCompanyIsSaving(false));
                if (response.success) {
                    if (!response.submittedHangfireJob) {
                        dispatch(notifyGreen("Company details were saved successfully."));
                    }
                    dispatch(requestSessionInfo());
                }
            })
            .catch(error => {
                dispatch(SetCompanyIsSaving(false));

                h.error("Error saving company details", error);
            });
    };

export const SaveCompanyPDF =
    (companyId: number, pdfContent: File) =>
    (dispatch: Dispatch): void => {
        dispatch(SetCompanyIsSaving(true));
        let token = "";
        if (document.getElementsByName("__RequestVerificationToken").length > 0) {
            token = (document.getElementsByName("__RequestVerificationToken")[0] as HTMLInputElement).value;
        }

        let pdfData = new FormData();
        pdfData.append("PDF", pdfContent);
        pdfData.append("CompanyId", companyId.toString());
        pdfData.append("__RequestVerificationToken", token);

        request(
            "/Companies/UploadCompanyPDF",
            {
                credentials: "same-origin",
                method: "POST",
                body: pdfData,
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { success: boolean };
                dispatch(SetCompanyIsSaving(false));
                if (response.success) {
                    dispatch(notifyGreen("Company PDF updated successfully."));
                    dispatch({
                        type: SET_COMPANY_PDF_FILENAME,
                        pdfFileName: "",
                    });
                }
            })
            .catch(error => {
                dispatch(SetCompanyIsSaving(false));
                h.error("Error uploading content.", error);
            });
    };

export const SaveCompanySingleView =
    (newSingleViewControl: ISingleViewControl) =>
    (dispatch: Dispatch): void => {
        dispatch(SetCompanyIsSaving(true));
        request(
            "/Companies/SaveSVTable",
            {
                method: "POST",
                credentials: "same-origin",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(newSingleViewControl),
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { success: boolean };
                dispatch(SetCompanyIsSaving(false));
                if (response.success) {
                    dispatch(notifyGreen("SingleView changes saved successfully."));
                }
            })
            .catch(error => {
                dispatch(SetCompanyIsSaving(false));
                h.error("Error saving SingleView changes", error);
            });
    };

export const DeleteCompany =
    (companyId: number, companyName: string) =>
    (dispatch: Dispatch): void => {
        dispatch(SetCompanyIsSaving(true));

        request(
            `/Companies/DeleteWholeCompany?companyId=${companyId}&companyName=${companyName}`,
            {
                method: "POST",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(SetCompanyIsSaving(false));
                if (data.success) {
                    dispatch(notifyGreen("Company deleted successfully."));
                    window.location.assign("/ae/admin/companies");
                } else {
                    dispatch(notifyError(data.message));
                }
            })
            .catch(error => {
                dispatch(SetCompanyIsSaving(false));

                h.error("Error deleting company", error);
                window.location.assign("/ae/admin/companies");
            });
    };

export const GetCompanyUsers =
    (companyId: number) =>
    (dispatch: Dispatch): Promise<Response> => {
        dispatch(SetCompanyIsLoadingUsers(true));

        return request(
            `/Companies/${companyId}/Users`,
            {
                credentials: "same-origin",
                method: "GET",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(SetCompanyIsLoadingUsers(false));
                if (data && data.users) {
                    dispatch({
                        type: SET_COMPANY_USERS,
                        companyUsers: data.users,
                    });
                }
            })
            .catch(error => {
                dispatch(SetCompanyIsLoadingUsers(false));

                h.error("Error getting company users.", error);
            });
    };

export const GetCompanyById =
    (companyId: number) =>
    (dispatch: Dispatch): void => {
        dispatch(SetCompanyByIdIsLoading(true));

        request(
            `/Companies/${companyId}/Details`,
            {
                credentials: "same-origin",
                method: "GET",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { company: ICompanyView };
                if (response && response.company) {
                    dispatch({
                        type: SET_COMPANY_BY_ID,
                        companyById: response.company,
                    });
                }
                dispatch(SetCompanyByIdIsLoading(false));
            })
            .catch(error => {
                dispatch(SetCompanyByIdIsLoading(false));

                h.error("Error getting company users.", error);
            });
    };

export const GetDeploySettings =
    (isFlowControl = false) =>
    (dispatch: Dispatch): Promise<Response> =>
        request(
            `/Companies/GetDeploySettings?isFlowControl=${isFlowControl}`,
            {
                method: "POST",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then((data: { deploySettings: Array<IDeploySetting> }) => {
                dispatch(SetCompanyIsLoading(false));
                if (data.deploySettings) {
                    if (isFlowControl) {
                        dispatch(SetFlowControlDeploySettings(data.deploySettings));
                    } else {
                        dispatch(SetDeploySettings(data.deploySettings));
                    }
                }
            })
            .catch((error: any) => {
                dispatch(SetCompanyIsLoading(false));
                h.error(`Error getting ${isFlowControl ? "flow control" : ""} deploy settings`, error);
            });

export const GetCurrentCompanyDetails =
    (companyId: number) =>
    (dispatch: Dispatch): Promise<Response> => {
        dispatch(SetCompanyIsLoading(true));

        return request(
            `/Companies/GetCompanyValuesEdit?id=${companyId}`,
            {
                credentials: "same-origin",
                method: "GET",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(SetCompanyIsLoading(false));
                if (data && data.model && data.model.Company) {
                    dispatch({
                        type: SET_COMPANY_DETAILS,
                        company: {
                            ...data.model.Company,
                            UpdatedBy: data.model.UpdatedBy,
                            CreatedBy: data.model.CreatedBy,
                            IsAdmin: data.model.IsAdmin,
                            DatabaseEnvironmentId: data.model.DatabaseEnvironmentId,
                        },
                    });
                    dispatch({
                        type: SET_COMPANY_CREATED_DATE,
                        companyCreateDate: formatDate(data.model.Company.CompanyDateCreated),
                    });
                    dispatch({
                        type: SET_COMPANY_UPDATED_DATE,
                        companyUpdateDate: formatDate(data.model.Company.CompanyDateUpdated),
                    });
                }

                if (data && data.model) {
                    dispatch({
                        type: SET_COMPANY_SV_CLIENT_KEYS,
                        svClientKeys: data.model.SVClientKeys,
                    });
                    dispatch({
                        type: SET_COMPANY_SV_TABLES,
                        svTables: data.model.SVTables,
                    });
                    dispatch({
                        type: SET_COMPANY_SV_CONTROL,
                        svControl: data.model.SingleViewControl,
                    });
                }

                dispatch(GetDeploySettings());
            })
            .catch(error => {
                dispatch(SetCompanyIsLoading(false));

                h.error("Error getting company details.", error);
            });
    };

export const GetAvailableFolders =
    (companyId: number) =>
    (dispatch: Dispatch): Promise<Response> => {
        dispatch(SetCompanyIsLoadingFolders(true));

        return request(
            `/Company/GetAvailableFolder?companyId=${companyId}`,
            {
                credentials: "same-origin",
                method: "GET",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(SetCompanyIsLoadingFolders(false));

                if (data.folderExist) {
                    dispatch({
                        type: SET_COMPANY_FOLDER_EXIST,
                        doesFolderExist: true,
                    });
                }
            })
            .catch(error => {
                dispatch(SetCompanyIsLoadingFolders(false));
                h.error("Error getting available company folders", error);
            });
    };

export const GetTapadDeliveryTemplates =
    (companyId: number, isEditCompany?: boolean) =>
    (dispatch: Dispatch): Promise<Response> => {
        dispatch(SetCompanyIsLoadingVerticals(true));

        return request(
            `/Companies/GetTapadDeliveryTemplates?companyId=${companyId}`,
            {
                method: "POST",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then((data: { tapadDeliveryTypes: any }) => {
                dispatch(SetCompanyIsLoadingVerticals(false));
                if (data.tapadDeliveryTypes) {
                    let type = SET_TAPAD_DELIVERY_TYPES;

                    if (isEditCompany) {
                        type = SET_COMPANY_TAPAD_DELIVERY_TYPES;
                    }

                    dispatch({
                        type,
                        tapadDeliveryTypes: data.tapadDeliveryTypes,
                    });
                }
            })
            .catch((error: any) => {
                dispatch(SetCompanyIsLoadingVerticals(false));
                h.error("Error getting available delivery types", error);
            });
    };

export const GetActivationDestinationSchedules =
    (destinationId: number, flowId: number) =>
    (dispatch: Dispatch): Promise<Response> =>
        request(
            `/Schedule/GetActivationDestinationSchedules?destinationId=${destinationId}&flowId=${flowId}`,
            {
                method: "POST",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                let response = data as unknown as Array<ISchedule>;
                if (response) {
                    dispatch(SetActivationDestinationSchedules(response));
                }
            })
            .catch((error: any) => {
                h.error("Error getting activation destination schedules", error);
            });

export const GetDestinationDedupeSettings =
    (companyId: number, isEditCompany?: boolean) =>
    (dispatch: Dispatch): Promise<Response> => {
        dispatch(SetCompanyIsLoadingVerticals(true));

        return request(
            `/Companies/GetDestinationDedupeSettings?companyId=${companyId}`,
            {
                method: "POST",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then((data: { destinationDedupeSettings: any }) => {
                dispatch(SetCompanyIsLoadingVerticals(false));
                if (data.destinationDedupeSettings) {
                    let type = SET_DESTINATION_DEDUPE_SETTINGS;
                    if (isEditCompany) {
                        type = SET_COMPANY_DESTINATION_DEDUPE_SETTINGS;
                    }

                    dispatch({
                        type,
                        destinationDedupeSettings: data.destinationDedupeSettings,
                    });
                }
            })
            .catch((error: any) => {
                dispatch(SetCompanyIsLoadingVerticals(false));
                h.error("Error getting destination dedupe settings", error);
            });
    };

export const GetAvailableVerticals =
    (companyId: number) =>
    (dispatch: Dispatch): Promise<Response> => {
        dispatch(SetCompanyIsLoadingVerticals(true));

        return request(
            `/Companies/GetAvailableVerticals?companyId=${companyId}`,
            {
                method: "POST",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(SetCompanyIsLoadingVerticals(false));

                if (data.verticals) {
                    dispatch({
                        type: SET_COMPANY_VERTICALS,
                        verticals: data.verticals,
                    });

                    if (data.currentVertical >= 0) {
                        dispatch({
                            type: SET_COMPANY_CURRENT_VERTICAL,
                            currentVertical: data.currentVertical,
                        });
                    }
                }
            })
            .catch(error => {
                dispatch(SetCompanyIsLoadingVerticals(false));
                h.error("Error getting available verticals", error);
            });
    };

export const GetInstructionDestinations =
    (companyId: number) =>
    (dispatch: Dispatch): Promise<Response> => {
        dispatch(SetCompanyIsLoadingDestinations(true));

        return request(
            `/Companies/GetInstructionDestinations?companyId=${companyId}`,
            {
                method: "POST",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                dispatch(SetCompanyIsLoadingDestinations(false));
                if (data.destinations) {
                    dispatch({
                        type: SET_COMPANY_DESTINATIONS,
                        destinations: data.destinations,
                    });
                }
            })
            .catch(error => {
                dispatch(SetCompanyIsLoadingDestinations(false));
                h.error("Error getting company instruction destinations", error);
            });
    };

export const GetSnowflakeSettings =
    (companyId: number) =>
    (dispatch: Dispatch): Promise<Response> => {
        dispatch(SetCompanyIsLoadingSnowflakeSettings(true));

        return request(
            `/Companies/GetSnowflakeSettings?companyId=${companyId}`,
            {
                method: "GET",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then((data: { snowflakeSettings: any }) => {
                dispatch(SetCompanyIsLoadingSnowflakeSettings(false));
                if (data.snowflakeSettings) {
                    dispatch({
                        type: SET_COMPANY_SNOWFLAKE_SETTINGS,
                        snowflakeSettings: data.snowflakeSettings,
                    });
                }
            })
            .catch((error: any) => {
                dispatch(SetCompanyIsLoadingSnowflakeSettings(false));
                h.error("Error getting Snowflake settings", error);
            });
    };

export const GetNotAllowedDeploySnowflakeTables =
    () =>
    (dispatch: Dispatch): Promise<Response> =>
        request(
            `/Companies/GetNotAllowedDeploySnowflakeTables`,
            {
                method: "GET",
                credentials: "same-origin",
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then((data: { notAllowedDeploySnowflakeTables: any }) => {
                if (data.notAllowedDeploySnowflakeTables) {
                    dispatch({
                        type: SET_NOT_ALLOWED_DEPLOY_SNOWFLAKE_TABLES,
                        notAllowedDeploySnowflakeTables: data.notAllowedDeploySnowflakeTables,
                    });
                }
            })
            .catch((error: any) => {
                h.error("Error getting not allowed deploy Snowflake tables", error);
            });

export const SaveCompanyFeatures =
    (company: number = 0, copyFeaturesType: number = 0, isFromCopyFeatures: boolean = false) =>
    (dispatch: Dispatch, getState: () => IAppState): Promise<Response> => {
        const state = getState();
        const features = state.features.companyNewSelectedFeatures;

        return request(
            "/Companies/FeaturesCurrCompany",
            {
                credentials: "same-origin",
                method: "POST",
                headers: { Accept: "application/json", "Content-Type": "application/json" },
                body: JSON.stringify({ features, company, copyFeaturesType }),
            },
            dispatch
        )
            .then(h.checkStatus)
            .then(h.toJson)
            .then(() => {
                dispatch(requestEnabledFeatures());
                dispatch(notifyGreen("Features saved successfully."));

                if (!isFromCopyFeatures) {
                    dispatch(GetCompanyFeatures());
                }
            })
            .catch(error => {
                h.error("Error updating features.", error);
            });
    };

export const GetCompanyRestrictedUser =
    (companyId: number) =>
    (dispatch: Dispatch): void => {
        fetch(`/api/companyrestricteduser/get/${companyId}`, {
            credentials: "same-origin",
            method: "GET",
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { success: boolean; restrictedUserName: string };
                if (response) {
                    if (response.success) {
                        dispatch(SetCompanyRestrictedUsername(response.restrictedUserName));
                    } else {
                        h.error("Error getting restricted user information.");
                    }
                }
            })
            .catch(error => {
                h.error("Error getting restricted user information.", error);
            });
    };

export const SaveCompanyRestrictedUser =
    (companyId: number, username: string, password: string) =>
    (dispatch: Dispatch): void => {
        if (!username || !password) {
            return;
        }

        let token = "";

        if (document.getElementsByName("__RequestVerificationToken").length > 0) {
            token = (document.getElementsByName("__RequestVerificationToken")[0] as HTMLInputElement).value;
        }

        fetch("/api/companyrestricteduser/save", {
            method: "POST",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                __RequestVerificationToken: token,
            },
            body: JSON.stringify({ companyId, username, password }),
        })
            .then(h.checkStatus)
            .then(h.toJson)
            .then(data => {
                const response = data as unknown as { success: boolean };
                if (response) {
                    if (response.success) {
                        dispatch(notifyGreen("Restricted User saved successfully."));
                    } else {
                        h.error("Error updating restricted user information.");
                    }
                }
            })
            .catch(error => {
                h.error("Error updating restricted user information.", error);
            });
    };
