/// TREESEARCH ///
export const treeSearchUserTyped = (text, isModal = false, enhancedSearch = false, conceptualOn = true) => ({
    type: "TREESEARCH_USER_TYPED",
    text,
    isModal,
    enhancedSearch,
    conceptualOn,
});

// Don't call this directly
export const setTreeSearch = text => ({
    type: "SET_TREESEARCH",
    text,
});

export const setEnhancedTreeSearch = () => ({
    type: "SET_ENHANCED_TREESEARCH",
});

export const clearTreeSearch = () => ({
    type: "CLEAR_TREESEARCH",
});

export const clearModalTreeSearch = () => ({
    type: "CLEAR_MODAL_TREESEARCH",
});

export const requestClearSearch = () => ({
    type: "CLEAR_SEARCH_REQUEST",
});

export const toggleConceptual = () => ({
    type: "CONCEPTUAL_TOGGLE",
});

export const toggleModalConceptual = () => ({
    type: "MODAL_CONCEPTUAL_TOGGLE",
});

// DISCOVERY (the data engine search) //
import { requestFieldTreeIfNeeded } from "./fieldTreeActions";
export const setDiscoverySearch = text => ({
    type: "SET_DISCOVERYSEARCH",
    text,
});
export const submitDiscoverySearch = text => dispatch => {
    dispatch({
        type: "SUBMIT_DISCOVERYSEARCH",
        text,
    });
    dispatch(requestFieldTreeIfNeeded());
};
export const submitDiscoveryBundleOnlySearch = bundles => ({
    type: "SUBMIT_DISCOVERY_BUNDLE_ONLY_SEARCH",
    bundles,
});

// DISCOVERY SELECTIONS //

export const toggleSelectedDiscoveryResult = id => ({
    type: "TOGGLE_SELECTED_DISCOVERY_RESULT",
    id,
});
export const clearSelectedDiscoveryResult = () => ({
    type: "CLEAR_SELECTED_DISCOVERY_RESULT",
});
export const unselectedDiscoveryResultSet = setOfIdsToRemove => ({
    type: "UNSELECT_DISCOVERY_RESULT_SET",
    setOfIdsToRemove,
});

// DISCOVERY CONCEPTUAL //
export const setDiscoveryConceptualScore = value => ({
    type: "SET_DISCOVERY_CONCEPTUAL_SCORE",
    value,
});

export const setDiscoveryConceptualMaxMatches = value => ({
    type: "SET_DISCOVERY_CONCEPTUAL_MAX_MATCHES",
    value,
});

// DISCOVERY FILTERS //
export const setDiscoveryFlagFilter = (flagName, value) => ({
    type: "SET_DISCOVERY_FLAG_FILTER",
    flagName,
    value,
});
export const setDiscoveryBundleFilter = value => ({
    type: "SET_DISCOVERY_BUNDLE_FILTER",
    value,
});

export const clearSearchAndSelection = () => dispatch => {
    dispatch(clearSelectedDiscoveryResult());
    dispatch(setDiscoverySearch(""));
};

export const setSearchLabels = newValue => ({
    type: "SET_SEARCH_LABELS",
    newValue,
});

export const setSearchLabels2 = newValue => ({
    type: "SET_SEARCH_LABELS2",
    newValue,
});

export const setSearchLabels3 = newValue => ({
    type: "SET_SEARCH_LABELS3",
    newValue,
});
